export default (match: number) => {
  let tier = {
    backgroundClasses: "bg-white text-default",
    badgeClasses: "",
    text: "",
    tier: 0,
  };

  switch (true) {
    case match > 89:
      tier = {
        backgroundClasses: "bg-green text-white",
        badgeClasses: "border-4 border-limonene-40",
        text: "strain match",
        tier: 5,
      };
      break;
    case match > 74 && match < 90:
      tier = {
        backgroundClasses: "bg-green text-white",
        badgeClasses: "",
        text: "strain match",
        tier: 4,
      };
      break;
    case match > 49 && match < 75:
      tier = {
        backgroundClasses: "bg-blue-grey text-white",
        badgeClasses: "",
        text: "strain match",
        tier: 3,
      };
      break;
    case match > 24 && match < 50:
      tier = {
        backgroundClasses: "bg-sepia text-white",
        badgeClasses: "",
        text: "strain match",
        tier: 2,
      };
      break;
    case match >= 0 && match < 25:
      tier = {
        backgroundClasses: "bg-default text-white",
        badgeClasses: "",
        text: "strain match",
        tier: 1,
      };
      break;
    default:
      break;
  }

  return tier;
};
