import consumerApi from "api/services/consumer-api";
import { StrainMatch } from "custom-types/StrainMatch";
import logError from "utils/logError";

const getStrainMatch = async (
  strainSlug: string,
  query: string,
): Promise<StrainMatch> => {
  try {
    const { data } = await consumerApi.get(
      `/api/strains/v1/${strainSlug}/match?${query}`,
    );

    if (!data) {
      return { match: -1, mismatches: {}, summary: "" };
    }

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getStrainMatch",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return { match: -1, mismatches: {}, summary: "" };
  }
};

export default getStrainMatch;
